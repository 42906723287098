<template>
    <section
        class="o-flexible-module"
        :class="{ 'o-flexible-module--flush': settings.spacing == 'flush' }">
        <div class="grid-container" v-if="settings.showComponentTitle">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">
                        Industries
                    </div>
                </div>
            </div>
        </div>

        <div :class="`c-industries c-industries--${settings.theme}`">
            <div class="grid-container">
                <div
                    class="c-industries__media"
                    :class="{ 'has-overlay': settings.backgroundOverlay.showBackgroundOverlay == true }">
                    <template v-for="(link, index) in links" :key="index">
                        <img
                            class="js-industries__media"
                            :class="{ 'is-active': index == 0 }"
                            :src="link.image.sourceUrl"
                            :srcset="link.image.srcSet"
                            :sizes="link.image.sizes"
                            :alt="link.image.altText"
                            :title="link.image.title"
                            :data-industry="index" />
                    </template>
                </div>

                <div class="grid-x grid-margin-x">
                    <div class="small-12 cell">
                        <div class="c-industries__wrapper">
                            <h2 class="c-industries__title" data-aos="fade-up">
                                {{ title }}
                            </h2>

                            <div class="c-industries__content s-wp-cms" data-aos="fade-up" v-if="content" v-html="content"></div>

                            <div class="c-industries__list">
                                <template v-for="(link, index) in links" :key="index">
                                    <NuxtLink :to="$convertUrl(link.link.url)" class="c-industries__single" data-aos="fade-up" :data-industry="index" @mouseover="activeIndustry(index)">
                                        {{ link.link.title }}
                                    </NuxtLink>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
    .c-industries__media.has-overlay:before {
        background-color: rgba(#000, v-bind(settings.backgroundOverlay.backgroundOverlayStrength));
    }
</style>

<script setup>
    const props = defineProps({
        title: {
            type: String,
            required: true
        },
        content: {
            type: String,
            required: false
        },
        links: {
            type: Object,
            required: true
        },
        settings: {
            backgroundOverlay: {
                showBackgroundOverlay: {
                    type: Boolean,
                    required: true,
                },
                backgroundOverlayStrength: {
                    type: Number,
                    required: true
                }
            },
            theme: {
                type: String,
                required: false
            },
            showComponentTitle: {
                type: Boolean,
                required: true,
            },
            spacing: {
                type: String,
                required: true
            }
        }
    });

    const activeIndustry = (industry) => {
        document.querySelectorAll('.js-industries__media').forEach((el) => {
            el.classList.remove('is-active');

            if(el.dataset.industry == industry) {
                el.classList.toggle('is-active');
            };
        });
    };
</script>
